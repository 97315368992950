export const takeovers = [
    {
        src: "lily.jpg",
        href: "https://www.instagram.com/p/ChU432AuJp3/?hl=en"
    },
    {
        src: "haley-stessman.jpg",
        href: "https://www.instagram.com/p/ChH_rcnOBNn/?hl=en"
    },
    {
        src: "kira-price.jpg",
        href: "https://www.instagram.com/p/ChC6yuluTGb/?hl=en"
    },
    {
        src: "destiny-berns.jpg",
        href: "https://www.instagram.com/p/Cg2CBk_uRqp/?hl=en"
    },
    {
        src: "dylan-tracy.jpg",
        href: "https://www.instagram.com/p/Cgw1108uOLy/?hl=en"
    },
    {
        src: "isabelle-martinez.jpg",
        href: "https://www.instagram.com/p/Cgj9jAGOZIz/?hl=en"
    },
    {
        src: "max-thomason.jpg",
        href: "https://www.instagram.com/p/Cge2Jm8uK3f/?hl=en"
    },
    {
        src: "matt-helberg.jpg",
        href: "https://www.instagram.com/p/CgR_jLfOyPj/?hl=en"
    },
    {
        src: "ryan-gamble.jpg",
        href: "https://www.instagram.com/p/CgM0oTZuddf/?hl=en"
    },
    {
        src: "srushti.jpg",
        href: "https://www.instagram.com/p/Cf_6wwiu88Y/?hl=en"
    },
    {
        src: "tyler-k.jpg",
        href: "https://www.instagram.com/p/Cf6xrfmOKDz/?hl=en"
    },
    {
        src: "laura-sitz.jpg",
        href: "https://www.instagram.com/p/CfwbIEJOuKG/?hl=en"
    },
    {
        src: "ella-p.jpg",
        href: "https://www.instagram.com/p/CfozAjGOEnG/?hl=en"
    },
    {
        src: "julia-mindy.jpg",
        href: "https://www.instagram.com/p/Cfb3Wb6OuxN/?hl=en"
    },
    {
        src: "aaron-w.jpg",
        href: "https://www.instagram.com/p/CfWtK2DuRs-/?hl=en"
    },
    {
        src: "lauren-w.jpg",
        href: "https://www.instagram.com/p/CfJ1nljOPSs/?hl=en"
    },
    {
        src: "sydney-clark.jpg",
        href: "https://www.instagram.com/p/CfEsCpcuM9q/?hl=en"
    },
    {
        src: "maddie-r.jpg",
        href: "https://www.instagram.com/p/Ce6X8tEu90u/?hl=en"
    },
    {
        src: "jacob-zenz.jpg",
        href: "https://www.instagram.com/p/CeyqBiJuE4o/?hl=en"
    },
    {
        src: "megan-h.jpg",
        href: "https://www.instagram.com/p/CelzMDfOTVE/?hl=en"
    },
    {
        src: "sam-longo.jpg",
        href: "https://www.instagram.com/p/CegliUJunSk/?hl=en"
    },
    {
        src: "alex-mona.jpg",
        href: "https://www.instagram.com/p/CeUBj6Gucr5/?hl=en"
    },
]