<template>
    <div class="container-flex column" id="footer">
        <div class="container-flex icon" id="footer-links">
            <a href="https://www.linkedin.com/in/lily-dosedel/" target="_blank"><fa icon="fa-brands fa-linkedin-in"/></a>
            <a href="https://www.instagram.com/lil.dos/?hl=en" target="_blank"><fa icon="fa-brands fa-instagram"/></a>
            <a href="contact"><fa icon="fa-solid fa-envelope"/></a>
        </div>
        <p>Site created by <a href="/" id="liao" target="_blank">Lily Dosedel</a> and <a href="https://liaozhu.dev/" id="liao" target="_blank">Liao Zhu</a></p>
    </div>
</template>
  
<script>
export default {
  name: "FooterVue"
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
  